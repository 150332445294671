import React from "react";

import Footer from "../components/footer";
import HeadContent from "../components/head";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import SubmitForm from "../components/submit-form";

const SubmitPage = () => {
  return (
    <>
      <Header />
      <SubmitForm />
      <Newsletter />
      <Footer />
    </>
  );
};

export default SubmitPage;

export const Head = ({ location }) => (
  <HeadContent title="Submit a site - Using Tailwind" location={location} />
);
